<script lang="ts" setup>
import { reactive, ref, computed, watch, onBeforeMount } from 'vue';
import OtpForm from './OtpForm.vue';
import { makeFormModel, resetForm, submitForm, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { emailField, passwordField } from 'ah-common-lib/src/form/models';
import { FormValidation, FieldModel } from 'ah-common-lib/src/form/interfaces';
import { AuthenticationServiceErrorCodes, MFAType, UserRole, UserStatus } from 'ah-api-gateways';
import EmailVerificationWarning from './EmailVerificationWarning.vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';
import { useServices } from 'bd-common/src/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { useRoute, useRouter } from 'vue-router/composables';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { VButton } from 'ah-common-lib/src/common/components';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import ToastComponent from '../common/ToastComponent.vue';
import { ToastType } from 'ah-common-lib/src/toast/toastInterfaces';
import { RouterLink } from 'vue-router';
import { AutoLoginDetails, LoginStage } from './userLoginInterfaces';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';

const props = withDefaults(
  defineProps<{
    autoLogin?: AutoLoginDetails | null;
  }>(),
  {
    autoLogin: null,
  }
);

const emit = defineEmits<{
  (e: 'stage-change', value: LoginStage): void;
  (e: 'login-success'): void;
  (e: 'login-failure', value: any): void;
}>();

const maxRegistrationRetries = 5;

const requestManager = useRequestManager().manager;

const authStore = useAuthStore();

const toast = useToast();

const services = useServices();

const router = useRouter();

const route = useRoute();

const otpForm = ref<InstanceType<typeof OtpForm> | null>(null);

const state = reactive({
  loginFM: makeLoginFM(),
  loginFV: null as FormValidation | null,
  otpTarget: '',
  otpType: MFAType.SMS_OTP,
  otpExpire: null as ExpiryTime | null,
  stage: LoginStage.login,
});

const verifiedEmail = computed(() => route.query.verified);

const formSubmitted = ref(false);

enum LoginError {
  incorrectCredentials = 'incorrectCredentials',
  adminUserLogin = 'adminUserLogin',
}

const loginError = ref<LoginError | null>(null);

function makeLoginFM() {
  return makeFormModel({
    name: 'loginForm',
    title: 'Login',
    fieldType: 'form',
    fields: [
      emailField('email', 'Email', {
        autocomplete: 'username',
        maxLength: 60,
        placeholder: 'Enter your email',
      }),
      passwordField('password', 'Password', false, { allowShowPassword: true, placeholder: 'Enter your password' }),
    ],
  });
}

function setStage(stage: LoginStage) {
  state.stage = stage;
  emit('stage-change', state.stage);
}

const passwordFieldModel = computed(() => getChildModel(state.loginFM, 'password') as FieldModel | undefined);

watch(
  verifiedEmail,
  () => {
    if (verifiedEmail.value && !props.autoLogin) {
      state.loginFM.email = verifiedEmail;
    }
  },
  { immediate: true }
);

onBeforeMount(() => {
  if (props.autoLogin) {
    setStage(LoginStage.autologin);
    login(props.autoLogin.email, props.autoLogin.password);
  } else {
    goToLogin();
  }
});

function backToLogin() {
  goToLogin();
  state.loginFM = makeLoginFM();
  if (state.loginFV) {
    resetForm(state.loginFV);
  }
}

function goToLogin() {
  setStage(LoginStage.login);
  state.otpExpire = null;
}

function onFormEvent() {
  if (passwordFieldModel.value) {
    setState(passwordFieldModel.value, 'errors', []);
  }
}

function closeLoginError() {
  loginError.value = null;
}

async function submitLoginForm() {
  formSubmitted.value = true;

  if (state.loginFV) {
    submitForm(state.loginFV);

    if (state.loginFV.$invalid) {
      return;
    }
  } else {
    return;
  }

  if (passwordFieldModel.value) {
    setState(passwordFieldModel.value, 'errors', []);
  }

  return login(state.loginFM.email, state.loginFM.password);
}

async function login(email: string, password: string, retries = 0) {
  requestManager.newPromise(
    'login',
    authStore
      .login({
        // FIXME: email should be username to match the payload of the login method
        username: email,
        password: password,
        silenceErrors: true,
      })
      .then((data) => {
        if (data?.role === UserRole.CLIENT_REGISTRATION && data?.status === UserStatus.TEMPORARY) {
          // Temporary Users do not need to check OTP via the login flow, but instead can be considered "logged in"
          // (the registration views will handle account verification)
          onLoginSuccess();
        } else if ((data?.role === UserRole.CLIENT_REGISTRATION && !data.individual) || data?.isOtpRequired) {
          return services.auth
            .refreshOtp()
            .toPromise()
            .then((res) => {
              if (res.tokenType) {
                state.otpType = res.tokenType;
              }
              state.otpTarget =
                state.otpType === MFAType.EMAIL_OTP
                  ? data.email.substring(data.email.indexOf('@') + 1)
                  : data.phoneNumber.substring(data.phoneNumber.length - 4);
              setStage(LoginStage.otp);
              if (res?.expiresIn) {
                state.otpExpire = res;
              }
            });
        } else {
          onLoginSuccess();
        }
      })
      .catch((error) => {
        if (
          error === 'ahAdminNotAllowed' ||
          error === 'userRoleNotAllowed' ||
          error.response?.data?.code === AuthenticationServiceErrorCodes.BAD_CREDENTIALS
        ) {
          loginError.value = LoginError.incorrectCredentials;
        } else if (error === 'userNotRegistered') {
          router.push('/register');
        } else if (error.response?.data?.code === AuthenticationServiceErrorCodes.UNVERIFIED_USER) {
          if (verifiedEmail.value && retries < maxRegistrationRetries) {
            login(email, password, retries + 1);
            return;
          }
          setStage(LoginStage.verifyEmail);
        } else {
          toast.error('Something unexpected happened while trying to login');
          goToLogin();
        }
        emit('login-failure', error);
      })
  );
}

function onLoginSuccess() {
  setStage(LoginStage.success);
  emit('login-success');
}

function onOtpError(error: any) {
  // We need to set a timeout so the toast doesn't get clear after a possible logout
  setTimeout(() => {
    if (error === 'userNotRegistered') {
      router.push('/register');
    } else if (error === 'userWithoutIndividual') {
      toast.error('Something unexpected happened while trying to login');
      goToLogin();
    }
    if (error === 'sessionNotFound') {
      toast.error('It seems like we are still creating your user. Try again in a couple of minutes.');
      goToLogin();
    }
  });
}

const errorTitle = computed(() => {
  if (loginError.value === LoginError.incorrectCredentials) {
    return `Incorrect email or password`;
  } else if (loginError.value == LoginError.adminUserLogin) {
    return 'Admin users cannot login to the platform directly.';
  }
  return '';
});

const errorMessage = computed(() => {
  if (loginError.value === LoginError.incorrectCredentials) {
    return `Please try again.`;
  } else if (loginError.value == LoginError.adminUserLogin) {
    return 'Please impersonate a user of this platform through the admin panel.';
  }
  return '';
});
</script>

<template>
  <div class="login">
    <div v-if="state.stage === LoginStage.login">
      <div class="headings-holder">
        <h1 class="main-heading">Welcome</h1>
        <h3 class="sub-heading">Please log in</h3>
      </div>
      <ToastComponent
        v-if="loginError"
        :id="'login-error-toast'"
        :toast-type="ToastType.danger"
        :message="errorMessage"
        :title="errorTitle"
        :dismiss="true"
        @close="closeLoginError"
        class="mb-4"
      />
      <ValidatedForm
        :fm="state.loginFM"
        :validation.sync="state.loginFV"
        @submit="submitLoginForm()"
        @form-event="onFormEvent"
      >
        <template #loginForm.password:label:after>
          <RouterLink class="float-right" to="/forgottenPassword">Forgot Password?</RouterLink>
        </template>
        <template #loginForm.password:passwordShownIcon="{ isPasswordShown }">
          <MaterialIcon class="password-visible-icon" :icon="isPasswordShown ? 'visibility' : 'visibility_off'" />
        </template>
      </ValidatedForm>

      <div class="form-actions text-center">
        <VButton
          :loading="requestManager.anyPending"
          label="Log in"
          @click="submitLoginForm()"
          class="btn-primary login-button"
          :disabled="state.loginFV && state.loginFV.$invalid"
        />
      </div>
    </div>
    <div v-else-if="state.stage === LoginStage.otp">
      <OtpForm
        ref="otpForm"
        :otp-target="state.otpTarget"
        :otp-type="state.otpType"
        :expiry="state.otpExpire || undefined"
        @cancel="backToLogin"
        @error="onOtpError"
        @success="onLoginSuccess"
      />
    </div>
    <div v-else-if="state.stage === LoginStage.verifyEmail" class="email-verification">
      <EmailVerificationWarning :email="state.loginFM.email" />
      <div class="text-center mt-3">
        <VButton label="Back to login" @click="setStage(LoginStage.login)" class="btn-stroked mx-3" />
      </div>
    </div>
    <div v-else-if="state.stage === LoginStage.autologin || state.stage === LoginStage.success">
      <LoadingOverlay :loading="requestManager.anyPending" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-button {
  width: 100%;
  margin-top: 3rem;
}

.headings-holder {
  margin-bottom: 2.5rem;

  .main-heading {
    font-size: $h1-font-size;
    font-style: normal;
    font-weight: bolder;
    line-height: 125%;
    margin-bottom: $font-size-xs;
  }

  .sub-heading {
    font-size: $h3-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
}

::v-deep .field-group-password {
  .input-group-append .input-group-text {
    // marking as !important to override all states of the appended element
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0.5em !important;
    cursor: pointer;
    user-select: none;

    .password-visible-icon {
      font-size: 1.5em;
    }
  }
}
</style>
