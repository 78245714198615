import {
  PaginatedQuery,
  PaginatedResponse,
  ComplianceCase,
  standardiseQuerySort,
  ListQuery,
  ExportListType,
  VersionedObject,
  FileUploadRequest,
  UploadedFile,
  FileCategory,
  DocumentExport,
  AMLScorecardFieldValue,
  AMLScoreCardValues,
  AMLScoreCard,
  AMLScorecardPreviewRequestPayload,
  AMLScoreCardRequestPayload,
  AddressHistoryListFilterParams,
  AddressHistoryItem,
  EntityAddressHistory,
  EntityAddressHistoryResponse,
} from '../models';
import { IndividualReference } from '../models/individual';
import { map } from 'rxjs/operators';
import { downloadFile } from 'ah-requests/helpers/download';
import { HttpBasedService, HttpService, HttpOptions, uploadFileWithUpdates, HttpRequestOptions } from 'ah-requests';

export interface ComplianceCaseListFilter {
  query?: string;
}
export class ComplianceAdminService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'complianceService' } },
      },
    });
  }

  private getComplianceBaseUrl() {
    return `${this.baseUrl}compliance/cases/`;
  }

  // Compliance Cases

  public listClientComplianceCases(
    params: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<ComplianceCase>>>
  ) {
    params = standardiseQuerySort(params);

    return this.get<PaginatedResponse<ComplianceCase>>(this.getComplianceBaseUrl(), {
      options,
      axiosConfig: {
        params: {
          ...params,
        },
      },
    });
  }

  // AML RISKS

  public getClientAMLScoreCard(clientId: string, options?: Partial<HttpRequestOptions<AMLScoreCard>>) {
    return this.get<AMLScoreCard>(`${this.baseUrl}aml-scorecard/${clientId}`, options);
  }

  public getClientAMLScorecardPreview(
    payload: AMLScorecardPreviewRequestPayload,
    options?: HttpRequestOptions<AMLScoreCardValues>
  ) {
    return this.post<AMLScoreCardValues>(`${this.baseUrl}aml-scorecard/preview`, payload, options);
  }

  public updateClientAMLScoreCard(
    payload: AMLScoreCardRequestPayload,
    options?: Partial<HttpRequestOptions<VersionedObject>>
  ) {
    return this.post<VersionedObject>(`${this.baseUrl}aml-scorecard`, payload, options);
  }

  public listAMLScorecardFieldValues(
    query?: PaginatedQuery,
    options?: Partial<HttpRequestOptions<PaginatedResponse<AMLScorecardFieldValue>>>
  ) {
    return this.get<PaginatedResponse<AMLScorecardFieldValue>>(`${this.baseUrl}aml-risk`, {
      ...options,
      axiosConfig: {
        ...options?.axiosConfig,
        params: query,
      },
    });
  }

  public getClientComplianceCase(clientId: string, options?: Partial<HttpOptions<ComplianceCase>>) {
    return this.get<ComplianceCase>(`${this.getComplianceBaseUrl()}${clientId}`, { options });
  }

  public updateClientComplianceCaseStatus(clientId: string, isCompanyClient: boolean, update: Partial<ComplianceCase>) {
    return this.put<VersionedObject>(
      `${this.baseUrl}clients/cases/${isCompanyClient ? 'company' : 'individual'}/${clientId}`,
      update
    );
  }

  public updateClientComplianceCase(
    clientId: string,
    update: Partial<ComplianceCase>,
    options?: Partial<HttpOptions<void>>
  ) {
    return this.put<void>(`${this.getComplianceBaseUrl()}${clientId}`, update, { options });
  }

  public downloadComplianceCasesList(
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle = 'Compliance Cases Listing'
  ) {
    return this.get<DocumentExport>(`${this.getComplianceBaseUrl()}export`, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public downloadComplianceCase(complianceId: string, fileFormat: ExportListType, documentTitle = 'Compliance Case') {
    return this.get<DocumentExport>(`${this.getComplianceBaseUrl()}${complianceId}/export`, {
      axiosConfig: {
        params: {
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public listUbos(clientId: string, options?: Partial<HttpOptions<PaginatedResponse<IndividualReference>>>) {
    return this.get<PaginatedResponse<IndividualReference>>(`${this.baseUrl}clients/cases/${clientId}/ubos`, {
      options,
    });
  }

  public getUboDocuments(clientId: string, uboId: string, options?: Partial<HttpOptions<UploadedFile[]>>) {
    return this.get<UploadedFile[]>(`${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents`, { options });
  }

  public submitUboDocument(clientId: string, uboId: string, category: FileCategory, file: File) {
    const formData = new FormData();

    formData.append(
      'model',
      JSON.stringify(<FileUploadRequest>{
        category,
        name: file.name,
      })
    );

    formData.append('file', file);

    return uploadFileWithUpdates<UploadedFile>(
      {
        axiosConfig: {
          url: `${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents`,
          method: 'POST',
          data: formData,
        },
      },
      (c) => this.request(c)
    );
  }

  public deleteDocument(clientId: string, uboId: string, fileId: string) {
    return this.delete<void>(`${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents/${fileId}`);
  }

  public downloadUboSyncDocument(clientId: string, uboId: string, file: UploadedFile) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: file.link ?? `${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents/${file.id}`,
      },
    }).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  public createAddressHistory(
    payload: EntityAddressHistory,
    options?: Partial<HttpOptions<EntityAddressHistoryResponse>>
  ) {
    return this.post<EntityAddressHistoryResponse>(`${this.baseUrl}address-history`, payload, { options });
  }

  public listAddressHistoryItems(
    filters: PaginatedQuery<AddressHistoryListFilterParams>,
    options?: Partial<HttpRequestOptions<PaginatedResponse<AddressHistoryItem>>>
  ) {
    filters = standardiseQuerySort(filters);
    return this.get<PaginatedResponse<AddressHistoryItem>>(`${this.baseUrl}address-history`, {
      ...options,
      axiosConfig: {
        ...options?.axiosConfig,
        params: filters,
      },
    });
  }
}
