import { AccountNumberType, BankingScheme, PaymentType } from '..';

export interface Wallet {
  id: string;
  /**
   * Client ID of Wallet. Only visible in client wallets - its absence signifies this is a Partner's wallet.
   */
  clientId?: string;
  /**
   * Client name of Wallet. Only visible in client wallets - its absence signifies this is a Partner's wallet.
   */
  clientName?: string;
  /**
   * Partner ID of Wallet. Only visible in admin endpoints.
   * It signifies either the Partner who owns the Wallet, or the Partner parent of the Client who owns the wallet, if clientId is set.
   */
  partnerId?: string;
  /**
   * Partner name of Wallet. Only visible in admin endpoints
   */
  partnerName?: string;
  /**
   * Wallet currency
   */
  currency: string;
  /**
   * Calculated balance for the wallet
   *
   * This will EXCLUDE all pending/in-transit outgoing funds, and INCLUDE any incoming funds
   */
  balance: number;
  /**
   * Calculated "movable" balance for the wallet (available for trades)
   *
   * This will EXCLUDE all pending/in-transit outgoing funds, but NOT INCLUDE any incoming funds
   */
  availableBalance: number;
  /**
   * How much of the wallet is in collateral. Not counted for balances.
   */
  collateralBalance: number;
  /**
   * In-transit outgoing funds
   */
  debitInTransit: number;
  /**
   * In-transit incoming funds
   */
  creditInTransit: number;
  /**
   * Pending incoming funds
   */
  pendingCredit: number;
  /**
   * Pending outgoing funds
   */
  pendingDebit: number;
  /**
   * Sum of outgoing funds (`debitInTransit` + `pendingDebit`)
   */
  outgoingBalance: number;
  /**
   * Sum of incoming funds (`creditInTransit` + `pendingCredit`)
   */
  incomingBalance: number;
  /**
   * Wallet type
   */
  walletType: WalletType;
  /**
   * Provider
   */
  provider?: WalletProviderType;
  /**
   * Account source
   */
  accountSource: WalletAccountSource;
  name: string;
  updatedAt: string;
  updatedBy: string;
  createdAt: string;
  createdBy: string;
  tradeBuyAmountInTransit: number;
  tradeSellAmountInTransit: number;
}

export enum WalletFundingPaymentType {
  ALL = 'ALL',
  PRIORITY = 'PRIORITY',
  REGULAR = 'REGULAR',
}

export enum WalletType {
  TRADING = 'TRADING',
  BANKING = 'BANKING',
}

export const walletTypeLabels: Record<WalletType, string> = {
  [WalletType.BANKING]: 'Banking',
  [WalletType.TRADING]: 'Trading',
};

export enum WalletProviderType {
  CURRENCY_CLOUD = 'CURRENCY_CLOUD',
  NATWEST = 'NATWEST',
}

export const walletProviderLabels: Record<WalletProviderType, string> = {
  [WalletProviderType.CURRENCY_CLOUD]: 'Currency Cloud',
  [WalletProviderType.NATWEST]: 'NatWest',
};

export enum InTransitValueExistenceType {
  DEBIT_IN_TRANSIT = 'DEBIT_IN_TRANSIT',
  CREDIT_IN_TRANSIT = 'CREDIT_IN_TRANSIT',
}

export const inTransitLabels: Record<InTransitValueExistenceType, string> = {
  [InTransitValueExistenceType.DEBIT_IN_TRANSIT]: 'Debit in Transit',
  [InTransitValueExistenceType.CREDIT_IN_TRANSIT]: 'Credit in Transit',
};

export enum PendingValueExistenceType {
  PENDING_DEBIT = 'PENDING_DEBIT',
  PENDING_CREDIT = 'PENDING_CREDIT',
}

export const pendingLabels: Record<PendingValueExistenceType, string> = {
  [PendingValueExistenceType.PENDING_DEBIT]: 'Pending Debit',
  [PendingValueExistenceType.PENDING_CREDIT]: 'Pending Credit',
};

export interface WalletsListFilters {
  walletType?: WalletType;
  provider?: WalletProviderType;
  inTransit?: InTransitValueExistenceType;
  pending?: PendingValueExistenceType;
  currency?: string;
  query?: string;
  queryBy?: string;
  excludeHouse?: boolean;
  partnerId?: string;
  hideEmpty?: boolean;
  clientId?: string;
  partnerWallet?: boolean;
}

export interface WalletFundingDetails {
  iban?: string;
  accountNumber?: string;
  accountHolderName: string;
  bankName: string;
  bankAddress: string;
  bankCountry: string;
  paymentType: WalletFundingPaymentType;
  paymentRail: string;
  routingCode: string;
  routingCodeType: string;
}

export enum WalletRoutingCodeType {
  BIC_SWIFT = 'BIC_SWIFT',
  SORT_CODE = 'SORT_CODE',
  ACH_ROUTING_NUMBER = 'ACH_ROUTING_NUMBER',
  WIRE_ROUTING_NUMBER = 'WIRE_ROUTING_NUMBER',
  ROUTING_CODE = 'ROUTING_CODE',
}

export interface WalletsAssociatedFundingDetailsFilters {
  accountNumberType?: AccountNumberType;
  bankingScheme?: BankingScheme;
  clientId?: string;
  currency?: string;
  partnerId?: string;
  paymentType?: PaymentType;
  query?: string;
  queryBy?: string;
  routingCodeType?: WalletRoutingCodeType;
  walletId?: string;
  walletProvider?: WalletProviderType;
  walletType?: WalletType;
}

export interface WalletAssociatedFundingDetails {
  accountHolderName: string;
  accountNumber: string;
  accountNumberType: string;
  bankAddress: string;
  bankCountry: string;
  bankName: string;
  bankingScheme: BankingScheme;
  bicSwift: string;
  clientId?: string;
  clientName?: string;
  currency: string;
  iban: string;
  id: string;
  partnerId?: string;
  partnerName?: string;
  paymentType: WalletFundingPaymentType;
  routingCode: string;
  routingCodeType: WalletRoutingCodeType;
  walletAvailableBalance: number;
  walletBalance: number;
  walletCollateralBalance: number;
  walletCreatedAt: string;
  walletCreatedBy: string;
  walletCreditInTransit: number;
  walletDebitInTransit: number;
  walletDeleted: boolean;
  walletDeletedAt: string;
  walletDeletedBy: string;
  walletId: string;
  walletIsVisible: boolean;
  walletName: string;
  walletPendingCredit: number;
  walletPendingDebit: number;
  walletProvider: WalletProviderType;
  walletUpdatedAt: string;
  walletUpdatedBy: string;
}

export enum WalletAccountSource {
  CURRENCY_CLOUD = 'CURRENCY_CLOUD',
  NATWEST_SAFEGUARDING = 'NATWEST_SAFEGUARDING',
  NATWEST_OPERATIONAL = 'NATWEST_OPERATIONAL',
  NATWEST_CASS = 'NATWEST_CASS',
}

export const walletAccountSourceLabels: Record<WalletAccountSource, string> = {
  [WalletAccountSource.CURRENCY_CLOUD]: 'Currency Cloud',
  [WalletAccountSource.NATWEST_SAFEGUARDING]: 'NatWest Safeguarding',
  [WalletAccountSource.NATWEST_OPERATIONAL]: 'NatWest Operational',
  [WalletAccountSource.NATWEST_CASS]: 'NatWest CASS',
};
