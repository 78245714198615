<script setup lang="ts">
import { tryOnBeforeMount } from '@vueuse/core';
import { PropType, ref, watch } from 'vue';

export interface AnimatedListingWithSubViewState {
  wideList: boolean;
  showInfo: boolean;
}

const props = defineProps({
  showInfo: {
    type: Boolean,
    required: true,
  },
  listingBlockClass: {
    type: String,
  },
  wideListBlockProps: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  narrowListBlockProps: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  infoBlockProps: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
});

const emit = defineEmits({
  'update:animatedState': (_model: AnimatedListingWithSubViewState) => true,
});

const animatedState = ref<AnimatedListingWithSubViewState>({
  wideList: true,
  showInfo: false,
});

tryOnBeforeMount(() => {
  animatedState.value = {
    wideList: !props.showInfo,
    showInfo: props.showInfo,
  };
});

function afterAnimation() {
  animatedState.value.wideList = !props.showInfo;
  animatedState.value.showInfo = props.showInfo;
}

function onAnimatedStateChange() {
  emit('update:animatedState', animatedState.value);
}

watch(animatedState, onAnimatedStateChange);

function toggleAnimationOnWalletChange(newVal: any, oldVal: any) {
  animatedState.value.showInfo = props.showInfo;
  if (oldVal && !newVal) {
    animatedState.value.showInfo = false;
  } else if (!oldVal && newVal) {
    animatedState.value.wideList = false;
  }
}

watch(() => props.showInfo, toggleAnimationOnWalletChange);
</script>

<template>
  <BoxGrid alignH="start">
    <BoxGridBlock
      :class="[animatedState.wideList ? 'with-wide-list' : 'with-narrow-list', listingBlockClass]"
      v-bind="animatedState.wideList ? wideListBlockProps : narrowListBlockProps"
      animateTransitions
      @transition-end="afterAnimation"
    >
      <slot name="listing" v-bind="{ animatedState }" />
    </BoxGridBlock>

    <Transition name="slide-and-fade" @after-leave="afterAnimation">
      <BoxGridItem v-if="animatedState.showInfo" v-bind="infoBlockProps">
        <slot name="sub-view" v-bind="{ animatedState }" />
      </BoxGridItem>
    </Transition>
  </BoxGrid>
</template>
