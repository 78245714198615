<script lang="ts" setup>
/**
 * Replaces DynamicTable from common-lib, including some default templates for sorting and toggling
 *
 * MUST be imported into Vue as "DynamicTable" to effectively replace the base implementation
 */
import { computed, useAttrs, ref } from 'vue';
import DynamicTableOriginal, { dynamicTableProps } from 'ah-common-lib/src/common/components/listing/DynamicTable.vue';
import { TableFieldDefinition, EditTableMode } from 'ah-common-lib/src/models';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import InfoTooltip from 'bd-common/src/components/common/InfoTooltip.vue';
import WarningSplash from '../common/WarningSplash.vue';
import LoadingSplash from '../common/LoadingSplash.vue';
import { useI18n } from 'ah-common-lib/src/i18n';

const props = defineProps({
  ...dynamicTableProps,
  withRowSubView: {
    type: [Boolean, String, Function],
    default: false,
  },
  clickableRow: {
    type: [Boolean, String, Function],
    default: false,
  },
  noResultsHeading: {
    type: String,
    default: 'No items found',
  },
  noResultsText: {
    type: String,
    default: '',
  },
  /**
   * Whether to use a shadow on the expanded row
   */
  withShadowedExpandedRows: {
    type: [String, Boolean],
    default: true,
  },
});

const emit = defineEmits({
  'open-row-subview': (_item: any) => true,
});

const attrs = useAttrs();

const i18n = useI18n();

const expandedRows = ref<string[]>([]);

const table = ref<InstanceType<typeof DynamicTableOriginal> | null>(null);

const tableFields = computed(() => {
  const fields: TableFieldDefinition[] = [...((attrs.fields as any) || [])];

  if (props.withRowDetails !== false) {
    fields.push({
      header: '',
      key: 'expandableRowToggle',
      class: 'toggle-col',
      sortable: false,
    });
  }
  if (props.withRowSubView !== false) {
    fields.push({
      header: '',
      key: 'selectableToggle',
      class: 'toggle-col',
      sortable: false,
    });
  }

  return fields;
});

function hasDetailsOpen(item: any) {
  return item[props.primaryKey] && expandedRows.value.includes(item[props.primaryKey]);
}

function hasRowDetails(item: any) {
  return typeof props.withRowDetails === 'function' ? props.withRowDetails(item) : props.withRowDetails !== false;
}

function isClickable(item: any) {
  return typeof props.clickableRow === 'function' ? props.clickableRow(item) : props.clickableRow !== false;
}

function bdRowClass(item: any, type: any) {
  const classes = [];
  if (props.rowClass) {
    classes.push(typeof props.rowClass === 'function' ? props.rowClass(item, type) : props.rowClass);
  }

  if (props.selectedItems.includes(item[props.primaryKey])) {
    classes.push('selected-row');
  }

  if (hasDetailsOpen(item)) {
    classes.push('b-table-has-details');
  }

  if (hasRowDetails(item)) {
    classes.push('with-details');
  }

  if (isClickable(item)) {
    classes.push('clickable');
  }

  return classes.join(' ');
}

function infoText(infoText?: string) {
  return infoText ? (i18n.t(infoText) as string) : '';
}
</script>

<template>
  <DynamicTableOriginal
    :class="[
      'bd-dynamic-table',
      {
        'row-clickable': withRowDetails,
      },
    ]"
    v-on="$listeners"
    v-bind="$attrs"
    :fields="tableFields"
    :dataLoadState="dataLoadState"
    :data="data"
    :withFlexScroll="withFlexScroll"
    :withSimpleScroll="withSimpleScroll"
    :showDetailsOnRowClick="false"
    :animateCols="animateCols"
    :itemsLabel="itemsLabel"
    :primaryKey="primaryKey"
    :withRowDetails="withRowDetails"
    :selectedItems="selectedItems"
    :editMode="editMode"
    thead-class="xh-sortable"
    :rowClass="bdRowClass"
    :expandedRows.sync="expandedRows"
    breakpoint="0px"
    ref="table"
  >
    <!-- header with sort -->
    <template #head()="{ fieldData, sort, sortBy, sortDesc }">
      <span @click="fieldData.sortable && sort()" class="table-title-wrapper">
        <span :class="['table-title', { 'with-sort': fieldData.sortable }]">
          <span class="title-inner">
            <slot :name="`headInner(${fieldData.key})`">{{ fieldData.header }} </slot>
            <InfoTooltip
              v-if="fieldData.info"
              class="th-tooltip search-info-icon"
              :title="fieldData.header"
              :text="infoText(fieldData.labelInfo)"
            />
          </span>
          <div
            class="sort-icon"
            :class="{ selected: (sortBy === fieldData.key || sortBy === fieldData.sortKey) && sortBy }"
            v-if="fieldData.sortable && editMode === EditTableMode.OFF"
          >
            <MaterialIcon
              v-if="(sortBy === fieldData.key || sortBy === fieldData.sortKey) && sortBy && sortDesc"
              icon="arrow_downward"
            />
            <MaterialIcon v-else icon="arrow_upward" />
          </div>
        </span>
      </span>
    </template>

    <!-- cell with selectable item chevron -->
    <template #cell(expandableRowToggle)="{ item }">
      <span v-if="hasRowDetails(item)" @click="table && table.toggleRowDetails(item)" class="nav-icon-holder">
        <MaterialIcon :class="['nav-icon', { open: hasDetailsOpen(item) }]" icon="expand_more" />
      </span>
    </template>
    <template #cell(selectableToggle)="{ item }">
      <span @click="emit('open-row-subview', item)" class="nav-icon-holder">
        <MaterialIcon class="nav-icon" icon="arrow_forward" />
      </span>
    </template>
    <template #tableNoResultsState>
      <WarningSplash
        :heading="noResultsHeading"
        :sub-heading="noResultsText"
        icon="tab_close"
        type="warning"
        class="mt-5 p-5"
      />
    </template>
    <template v-if="dataLoadState === 'error'">
      <slot name="tableErrorState"> </slot>
    </template>

    <template #tableLoadState>
      <LoadingSplash />
    </template>

    <!-- slots from parent -->
    <template v-for="(_, name) in $scopedSlots" #[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
    <!-- row-details placed after slots from parent to override row-details slot, if added-->
    <template #row-details="slotScope">
      <div class="row-details-shadow" v-if="withShadowedExpandedRows !== false"></div>
      <slot name="row-details" v-bind="slotScope"></slot>
    </template>
  </DynamicTableOriginal>
</template>

<style lang="scss" scoped>
.bd-dynamic-table ::v-deep {
  th {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    @include themedPropColor('border-bottom-color', $color-bdPrimary);
    padding: 5px 20px 10px 20px;

    font-size: $font-size-base;
    font-weight: 600;
    vertical-align: top;

    .table-title {
      position: relative;

      .title-inner {
        color: getColor($color-bdDarkBlue);
      }

      &.with-sort {
        padding-right: 1.4em;
        position: relative;
        display: block;
      }
      .sort-icon {
        position: absolute;
        top: -0.1em;
        right: 0;
        font-weight: 300;
        font-size: 1.25em;
        user-select: none;
        color: getColor($color-bdPrimary);
        cursor: pointer;

        > span {
          position: relative;
          display: flex;
          top: 2px;
          left: 5px;
          width: 20px;
          height: 20px;
          justify-content: center;
        }

        &.selected,
        &:hover {
          > span {
            background: getColorWithOffsetLightness($color-bdPrimary, 30%);
            border-radius: 50%;
            outline: 0.1em solid getColorWithOffsetLightness($color-bdPrimary, 30%);
          }
        }
        .material-symbols-outlined {
          position: absolute;
          top: 10px;
        }
      }
    }
  }

  tr {
    &:not(.p-datatable-row-expansion):hover {
      > td {
        background-color: getColorWithOffsetLightness($color-bdPrimary, 30%);
      }
    }

    &.p-datatable-row-expansion {
      position: relative;

      .row-details-shadow {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        overflow: hidden;
        pointer-events: none;

        &::after {
          box-shadow: inset 0 0 10px 0 getColorWithAlpha($color-bdPrimary, 0.6);
          position: absolute;
          content: ' ';
          top: 0;
          right: -20px;
          left: -20px;
          bottom: 0;
        }
      }
    }

    &.edited-row {
      background-color: getColorWithOffsetLightness($color-bdPrimary, 30%);
    }
  }

  td {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: getColor($color-bdMediumGrey);
    padding: 15px 20px;
    font-size: $font-size-sm;
    font-weight: 400;
    color: getColor($color-bdDarkBlue);

    &.toggle-col {
      min-width: 0;
      vertical-align: middle;
      text-align: right;

      .nav-icon-holder {
        font-weight: 300;
        cursor: pointer;
        display: inline-block;

        .nav-icon {
          display: block;
          transition: transform 0.5s;
          border-radius: 50%;
          color: getColor($color-bdPrimary);
          font-size: 1.6em;
          line-height: 1em;
          margin-top: 0.1em;

          &.open {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
</style>
