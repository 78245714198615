<script lang="ts" setup>
import { DateSelector } from 'ah-common-lib/src/common/components';
import MaterialIcon from '../common/MaterialIcon.vue';

// TODO refactor DateSelector to composition API , expose props directly (instead of using $attrs)
</script>

<template>
  <DateSelector
    v-bind="$attrs"
    v-on="$listeners"
    placement="bottom"
    autoSubmit
    :commonChoices="[]"
    class="bd-date-selector"
  >
    <template #button="{ showModal, selectedDateDescription, isDateSet, isOpen }">
      <div :class="['form-control date-button', { unset: !isDateSet, 'focus-style': isOpen }]" @click="showModal">
        <span class="message"> {{ selectedDateDescription }} </span>
        <MaterialIcon icon="calendar_today" class="date-icon" />
      </div>
    </template>
  </DateSelector>
</template>

<style scoped lang="scss">
.date-button {
  position: relative;
  color: getColor($color-text);
  &.unset {
    color: getColor($color-bdDarkGrey);
  }

  .date-icon {
    position: absolute;
    right: 0.6em;
    font-size: 1.5em;
    top: 0.1em;
    color: getColor($color-bdBlack);
  }
}
</style>
