import { catchError, map } from 'rxjs/operators';
import { makeStoreSupportData, StoreSupportData } from './supportData';
import {
  LocalizedFieldData,
  LocalizedBeneficiaryFieldData,
  PurposeCodeResponse,
  RoutingCodeData,
  PaymentRailData,
  BankingScheme,
  BeneficiaryType,
  PositionLimitsProfile,
  EntitySector,
  EntityType,
  CurrencyData,
  FeePaymentType,
  CountryInfo,
  ISOCodeInfo,
  CountryJurisdiction,
  PaymentLimitData,
  SingleCurrency,
  HedgingInstruments,
} from 'ah-api-gateways';
import { of } from 'rxjs';
import { FieldOption } from 'ah-common-lib/src/form/interfaces';
import { deterministicJSON } from 'ah-common-lib/src/helpers/serialization';
import Vue from 'vue';
import { CachedItem } from 'ah-common-lib/src/helpers/cachedItem';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { defineStore } from 'pinia';
import { useAuthStore } from './authStore';
import { sortBy } from 'lodash';

let sd!: StoreSupportData;

export const useSettingsStore = defineStore('settingsModule', {
  persist: true,
  state: () => {
    if (!sd) {
      sd = makeStoreSupportData();
    }
    return {
      darkMode: false,
      countryISOSubdivisions: {} as { [countryCode: string]: CachedItem<ISOCodeInfo[]> },
      countries: new CachedItem<CountryInfo[]>(),
      countriesJurisdictions: new CachedItem<CountryJurisdiction[]>(),
      paymentRails: new CachedItem<PaymentRailData[]>(),
      localPaymentLimits: {} as { [currency: string]: CachedItem<PaymentLimitData> },
      swiftPaymentLimits: {} as { [currency: string]: CachedItem<PaymentLimitData> },
      entitySectors: new CachedItem<EntitySector[]>(),
      entityTypes: new CachedItem<EntityType[]>(),
      routingCodeTypes: new CachedItem<RoutingCodeData[]>(),
      currencyData: new CachedItem<CurrencyData[]>(),
      currencyDataSpot: {} as { [key: string]: CachedItem<SingleCurrency[]> },
      currencyDataForward: {} as { [key: string]: CachedItem<SingleCurrency[]> },
      currencyBankAccountFields: {} as { [key: string]: CachedItem<LocalizedBeneficiaryFieldData[]> },
      bankPurposeCodes: {} as { [key: string]: CachedItem<PurposeCodeResponse> },
      currencyAddressFields: {} as { [key: string]: CachedItem<LocalizedFieldData[]> },
      limitsProfile: new CachedItem<PositionLimitsProfile | null>(),
    };
  },
  getters: {
    displayRate() {
      return (val?: number, emptyValue: string = ''): string => {
        return typeof val === 'number' ? val.toFixed(this.ratePrecision) : emptyValue;
      };
    },
    ratePrecision() {
      return 4;
    },
    currencyPrecision() {
      return 2;
    },
    entityTypeList(state) {
      return state.entityTypes.item;
    },
    allCountries(state) {
      return state.countries.item ?? [];
    },
    routingCodes(state) {
      return state.routingCodeTypes.item ?? [];
    },
    activeCountries(state) {
      return (state.countries.item ?? []).filter((c) => c.enabled);
    },
    countrySubdivisions(state) {
      return (countryCode: string) => state.countryISOSubdivisions[countryCode]?.item || [];
    },
    currencies(state) {
      return state.currencyData.item ?? [];
    },
    getCurrenciesSpot(state) {
      return (clientId: string) => {
        return state.currencyDataSpot[clientId]?.item ?? [];
      };
    },
    getCurrenciesForward(state) {
      return (clientId: string) => {
        return state.currencyDataForward[clientId]?.item ?? [];
      };
    },
    entityTypesList(state) {
      return state.entityTypes.item ?? [];
    },
    currencyPairs(state) {
      const out: string[] = [];
      if (state.currencyData.item) {
        state.currencyData.item.forEach((ccy1) => {
          state.currencyData.item!.forEach((ccy2) => {
            if (ccy1 !== ccy2) out.push(`${ccy1.currency}${ccy2.currency}`);
          });
        });
      }
      return out;
    },
    currenciesAsOptions() {
      return (withName = true) => {
        return (this.currencies ?? []).map((i: CurrencyData) => ({
          label: `${i.currency} ${withName ? i.name : ''}`,
          value: i.currency,
        })) as FieldOption[];
      };
    },
    clientLimitsProfile(state) {
      return state.limitsProfile.item ?? null;
    },
    getCountryCurrency(state) {
      return (cc: string) => {
        if (!state.countries.item) {
          return null;
        }
        return state.countries.item.find((i) => i.cc === cc)?.currency ?? null;
      };
    },
    getPaymentRail(state) {
      return (params?: { currency?: string; bankingScheme?: BankingScheme; type?: FeePaymentType }) => {
        if (!state.paymentRails.item) return [];
        return state.paymentRails.item.filter((rail) => {
          if (params?.currency && rail.currency !== params.currency) return false;
          if (params?.bankingScheme && rail.bankingScheme !== params.bankingScheme) return false;
          if (params?.type && rail.type !== params.type) return false;
          return true;
        });
      };
    },
    getPaymentLimit(state) {
      return (currency: string, bankingScheme = BankingScheme.LOCAL) => {
        if (bankingScheme === BankingScheme.LOCAL) {
          return state.localPaymentLimits[currency]?.item;
        } else {
          return state.swiftPaymentLimits[currency]?.item;
        }
      };
    },
    KYCCountries(state) {
      return state.countriesJurisdictions.item?.filter((c) => c.isAutomated);
    },
    nonKYCCountries(state) {
      return state.countriesJurisdictions.item?.filter((c) => !c.isAutomated);
    },
  },
  actions: {
    loadPaymentRails(force = false): Promise<PaymentRailData[]> {
      return CachedItem.loadCachedItem(this.paymentRails, sd.s.bankingReference.listPaymentRails({}), force);
    },
    loadCountries(force = false): Promise<CountryInfo[]> {
      return CachedItem.loadCachedItem(
        this.countries,
        sd.s.customerReference.listCountries({ pageSize: 10000, sort: 'name', sortDirection: 'ASC' }).pipe(
          map((i) =>
            sortBy(
              i.list.filter((country) => country.cc && country.currency),
              (i) => i.name
            )
          )
        ),
        force
      );
    },
    loadISOCodes(payload: { force?: boolean; countryCode: string }): Promise<ISOCodeInfo[]> {
      if (!this.countryISOSubdivisions[payload.countryCode]) {
        Vue.set(this.countryISOSubdivisions, payload.countryCode, new CachedItem<ISOCodeInfo[]>());
      }
      return CachedItem.loadCachedItem(
        this.countryISOSubdivisions[payload.countryCode],
        sd.s.customerReference.listISOCodes({ countryCode: payload.countryCode }),
        payload.force ?? false
      );
    },
    loadKYCCountries(force = false) {
      return CachedItem.loadCachedItem(
        this.countriesJurisdictions,
        sd.s.customerReference
          .listCountriesJurisdiction({ size: 10000 })
          .pipe(map((i) => i.list.filter((kycCountries) => kycCountries.countryCode))),
        force
      );
    },
    loadEntityTypes(force = false) {
      return CachedItem.loadCachedItem(
        this.entityTypes,
        sd.s.customerReference.listEntityTypes({ pageSize: 10000, sort: 'legalForm' }).pipe(map((i) => i.list)),
        force
      );
    },
    loadRoutingCodeTypes(force = false) {
      return CachedItem.loadCachedItem(
        this.routingCodeTypes,
        sd.s.bankingReference.listRoutingCodes({ errors: { silent: true } }),
        force
      );
    },
    loadEntitySectors(force = false) {
      return CachedItem.loadCachedItem(
        this.entitySectors,
        sd.s.customerReference.listEntitySectors({ pageSize: 20000 }).pipe(map((i) => i.list)),
        force
      );
    },
    loadTradeableCurrencies(force = false) {
      return CachedItem.loadCachedItem(
        this.currencyData,
        sd.s.bankingReference
          .listCurrencies()
          .pipe(map((currencies) => currencies.sort((a, b) => a.currency.localeCompare(b.currency)))),
        force
      );
    },
    loadTradeableCurrenciesForSpot(payload: { force?: boolean; oboClientId?: string } = { force: false }) {
      if (!useAuthStore().isClientUser && !payload.oboClientId) {
        throw 'You must set the client id to access list of currencies';
      }

      const clientId = payload.oboClientId ?? useAuthStore().loggedInIdentity?.client?.id;

      if (!clientId) {
        throw 'You must set the client id to access list of currencies';
      }

      if (!this.currencyDataSpot[clientId]) {
        Vue.set(this.currencyDataSpot, clientId, new CachedItem());
      }

      return CachedItem.loadCachedItem(
        this.currencyDataSpot[clientId],
        sd.s.liquidityProvider
          .listCurrencies(HedgingInstruments.FX_SPOT, payload.oboClientId)
          .pipe(map((currencies) => currencies.sort((a, b) => a.currency.localeCompare(b.currency)))),
        payload.force
      );
    },
    loadTradeableCurrenciesForForward(payload: { force?: boolean; oboClientId?: string } = { force: false }) {
      if (!useAuthStore().isClientUser && !payload.oboClientId) {
        throw 'You must set the client id to access list of currencies';
      }

      const clientId = payload.oboClientId ?? useAuthStore().loggedInIdentity?.client?.id;

      if (!clientId) {
        throw 'You must set the client id to access list of currencies';
      }

      if (!this.currencyDataForward[clientId]) {
        Vue.set(this.currencyDataForward, clientId, new CachedItem());
      }

      return CachedItem.loadCachedItem(
        this.currencyDataForward[clientId],
        sd.s.liquidityProvider
          .listCurrencies(HedgingInstruments.FX_FORWARD, payload.oboClientId)
          .pipe(map((currencies) => currencies.sort((a, b) => a.currency.localeCompare(b.currency)))),
        payload.force
      );
    },
    loadCurrencyBankAccountFields(payload: {
      params: {
        currency: string;
        bankingScheme?: BankingScheme;
        beneficiaryType: BeneficiaryType;
        bankCountry: string;
      };
      force?: boolean;
    }) {
      const key = deterministicJSON(payload.params);
      if (!this.currencyBankAccountFields[key]) {
        Vue.set(this.currencyBankAccountFields, key, new CachedItem());
      }
      return CachedItem.loadCachedItem(
        this.currencyBankAccountFields[key],
        sd.s.bankingReference.listBeneficiariesFields(payload.params, { errors: { silent: true } }),
        !!payload.force
      );
    },
    loadBankPurposeCodes(payload: {
      params: {
        currency: string;
        bankAccountCountry: string;
        beneficiaryType: BeneficiaryType;
        bankingScheme: BankingScheme;
      };
      force?: boolean;
    }) {
      const key = deterministicJSON(payload.params);
      if (!this.bankPurposeCodes[key]) {
        Vue.set(this.bankPurposeCodes, key, new CachedItem());
      }
      return CachedItem.loadCachedItem(
        this.bankPurposeCodes[key],
        sd.s.bankingReference.listPurposeCodes(payload.params, { errors: { silent: true } }),
        !!payload.force
      );
    },
    loadClientLimitsProfile(payload: { force: boolean; clientId?: string } = { force: false }) {
      const authStore = useAuthStore();

      if (!this.limitsProfile) {
        this.limitsProfile = new CachedItem<PositionLimitsProfile>();
      }
      const id = payload.clientId ? payload.clientId : authStore.loggedInIdentity!.client!.id;
      return CachedItem.loadCachedItem(
        this.limitsProfile,
        sd.s.limits
          .getClientLimitProfile(id, {
            errors: { silent: true },
          })
          .pipe(
            catchError(() => {
              return of(null);
            })
          ),
        payload.force
      );
    },
    loadCurrencyAddressFields(payload: {
      params: {
        currency: string;
        bankingScheme?: BankingScheme;
        beneficiaryType: BeneficiaryType;
      };
      force?: boolean;
    }) {
      const key = deterministicJSON(payload.params);
      if (!this.currencyAddressFields[key]) {
        Vue.set(this.currencyAddressFields, key, new CachedItem<LocalizedFieldData[]>());
      }
      return CachedItem.loadCachedItem(
        this.currencyAddressFields[key],
        sd.s.customerReference.listAddressFields(payload.params),
        !!payload.force
      );
    },
    loadPaymentLimit(payload: { force: boolean; currency: string; bankingScheme: BankingScheme }) {
      if (!payload.force) {
        const limit = this.getPaymentLimit(payload.currency, payload.bankingScheme);

        if (limit) {
          return Promise.resolve(limit);
        }
      }

      Vue.set(
        payload.bankingScheme === BankingScheme.LOCAL ? this.localPaymentLimits : this.swiftPaymentLimits,
        payload.currency,
        new CachedItem<PaymentLimitData>()
      );

      return CachedItem.loadCachedItem(
        payload.bankingScheme === BankingScheme.LOCAL
          ? this.localPaymentLimits[payload.currency]
          : this.swiftPaymentLimits[payload.currency],
        sd.s.bankingReference.getPaymentLimit({
          beneficiaryCurrency: payload.currency,
          beneficiaryBankingScheme: payload.bankingScheme,
        }),
        !!payload.force
      );
    },
    [commonStoreActions.onLogin]() {
      return this.loadAllItems(true);
    },
    async [commonStoreActions.onLogout]() {
      this.clearStore();
    },
    async [commonStoreActions.onLogoutOtherTab]() {
      this.clearStore();
    },
    async [commonStoreActions.onOutMaintenance]() {
      const authStore = useAuthStore();
      if (authStore.isLoggedIn) {
        return this.loadAllItems(true);
      }
    },
    clearStore() {
      this.currencyDataForward = {};
      this.currencyDataSpot = {};
    },
    loadAllItems(force = false) {
      let currenciesRequests: Promise<any>[] = [];
      if (useAuthStore().isClientUser) {
        currenciesRequests = [
          this.loadTradeableCurrenciesForForward({ force: true }),
          this.loadTradeableCurrenciesForSpot({ force: true }),
        ];
      }

      const requests: Promise<any>[] = [
        ...currenciesRequests,
        this.loadCountries(force),
        this.loadTradeableCurrencies(force),
        this.loadPaymentRails(force),
      ];

      return Promise.all(requests);
    },
  },
});
